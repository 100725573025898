<template>
  <div class="container-anuncio">

 

<a :href="getLink" target="_blank"><img v-if="imagem != null" :src="imagem" class="img-fluid d-block mx-auto"  /></a>

<img v-if="ads.length == 0" :src="padrao" class="img-fluid d-block mx-auto"/> 
  </div>
</template>

<script>
import servidor from '@/servidor'


export default {
  name: 'Anuncio',
  components: {},
  props: {
    id: Number,
    padrao:String,
    mobile:Boolean
  },
  data(){
    return {
     imagem:null,
     clock:null,
     ads:[],
     posicao:0
     
    }
  },
  methods:{
    alternar(){
      if((this.posicao + 1) < this.ads.length){
         this.posicao++;
         this.imagem = servidor+'anuncios/'+ this.getImagem;
      }else{
        this.posicao = 0;
        this.imagem = servidor+'anuncios/'+ this.getImagem;
      }
    },
     acionar(){
       let contexto = this;
       this.clock = setInterval(contexto.alternar, 5000);
     },
      getAnuncios() {
      
        fetch(servidor+'gerenciaAnuncios/getAnuncios/'+this.id)
          .then((response) => response.json())
          .then((data) => {
           this.ads = data;
           if(this.ads.length > 0){
             this.posicao = 0;
             this.imagem = servidor+'anuncios/'+ this.getImagem;
             this.acionar();
           }
          });
    }
   
  },
  computed:{
   getImagem(){
     if(this.mobile){
       return this.ads[this.posicao].AN_MOBILE;
     }else{
        return this.ads[this.posicao].AN_IMAGEM;
     }
   },
   getLink(){
     if(this.ads.length > 0 && this.ads[this.posicao] != undefined){
       return this.ads[this.posicao].AN_LINK;
     }else{
        return '';
     }
   }
  },
  mounted(){
    this.getAnuncios();
    
  },
  beforeUnmount(){
    if(this.clock != null){
      clearInterval(this.clock);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
  height:0;
}
</style>
