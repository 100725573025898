<template>
  <div class="container-card-receita col-sm-12">

    <div class="card" style="margin-bottom:20px;" >
  <div class="card-header" :style="{background:cor}" style="border-bottom:2px solid #FFF !important;">

<svg v-if="showIconeDeletar" style="color:#FFF;cursor:pointer;" @click="deletar" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash float-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>

    <h4 class="bold white">{{receita.nome}}</h4>
    <p class="bold white">{{receita.ingredientes.length}} Ingrediente(s) adicionado(s)</p>
  </div>
  <div class="card-body" :style="{background:cor}">
    <div class="row">
      <div class="col-sm-7">
        <h4 class="bold white">{{receita.categoria}}</h4>
      </div>
      <div class="col-sm-5">
        <img :src="'/img/'+icone" class="img-fluid float-right" style="margin-top:-20px;"/>
      </div>
    </div>
    
    <div class="row">
      <div :style="{background:cor2}" class="col box-status text-center"> 
         <img src="/img/icon_det01.png" class="img-fluid" style=""/>
         <p class="white bold txt-icones">
        {{ (receita.dieta != '') ? receita.dieta:'N/A'}}</p> 
        </div>

      <div :style="{background:cor2}" class="col box-status text-center">
        <img src="/img/icon_det02.png" class="img-fluid" style=""/>
         <p class="white bold txt-icones">{{receita.rendimento}}<br>Pessoas</p>
          </div>

      <div :style="{background:cor2}" class="col box-status text-center">
        <img src="/img/icon_det03.png" class="img-fluid" style=""/>
         <p class="white bold txt-icones">{{receita.tempo}} Min</p>
       </div>

       <div :style="{background:cor2}" class="col box-status text-center"> 
         <img src="/img/icon_det04.png" class="img-fluid" style=""/>
         <p class="white bold txt-icones">{{receita.dificuldade}}</p> 
         </div>

       <div :style="{background:cor2}" class="col box-status text-center">
         <img src="/img/icon_det05.png" class="img-fluid" style=""/>
          <p class="white bold txt-icones">{{receita.custo}}</p>
           </div>

     
    </div>

  </div>

   <div class="card-footer" style="background:#FFF !important;">

<!-- table -->
<div class="table-responsive">
<table class="table">
  <thead class="thead-light">
     <tr>
  
      <th scope="col" colspan="2">Medidas Domésticas</th>
      <th scope="col" colspan="2">Medidas Decimais</th>
     
      <th scope="col"></th>
     
       
       
    </tr>
    <tr>
      <th scope="col">Quant</th>
      <th scope="col">UNID</th>
      <th scope="col">Quant</th>
      <th scope="col">UNID</th>
      <th scope="col">Ingrediente</th>
       
       
       
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item,index) in receita.ingredientes" :key="'ingredienteCarrinho'+index">
      <td>{{item.qtd.replace('.',',').replace(',0','')}}</td>
      <td>{{item.medida}}</td>
      <td>{{item.volume.replace('.',',').replace(',0','')}}</td>
      <td>{{item.decimal}}</td>
      <td>{{item.nome}}</td>
      
     
     
     
    </tr> 
   
  </tbody>
</table>
 
</div>
<!-- -->

     
    <router-link v-if="getRota ==false" :to="'/cadastro/'" class="btn btn-primary bt-visualizar" :style="{background:cor}">Cadastre-se para visualizar</router-link>
    <router-link v-if="getRota == true" :to="'/visualizarReceita/'+receita.id" class="btn btn-primary bt-visualizar" :style="{background:cor}">Visualizar Receita</router-link>
  </div>
</div>




  </div>
</template>

<script>

export default {
  name: 'CardCompras',
  components: {},
  props: {
    receita: Object,
    showIconeDeletar: Boolean
  },
  data(){
    return {
     cor:'',
     cor2:'',
     icone:''
     
    }
  },
  computed:{
    getRota(){
      return (this.$store.state.usuario == null) ? false : true;
    }
  },
  methods:{
    deletar(){
      this.$emit('deletarReceita');
    },
    colorir(){
      this.media = parseInt(this.receita.nota);
      switch(this.receita.categoria){
       
         case 'Entradas':
          this.cor = "#17a959 !important";
          this.cor2 = "#085028 !important";
          this.icone = 'icon_categoria1.png';
        break;
         case 'Sopas, Cremes e Caldinhos':
          this.cor = "#eba800 !important";
          this.cor2 = "#da7400 !important";
          this.icone = 'icon_categoria2.png';
        break;
         case 'Aperitivos, Comidinhas e Lanches':
          this.cor = "#da7400 !important";
          this.cor2 = "#8f4e03 !important";
          this.icone = 'icon_categoria3.png';
        break;
        case 'Pratos Principais':
          this.cor = "#ec7500 !important";
          this.cor2 = "#f33b00 !important";
          this.icone = 'icon_categoria4.png';
        break;
         case 'Acompanhamentos':
          this.cor = "#a8be3d !important";
          this.cor2 = "#539400 !important";
          this.icone = 'icon_categoria5.png';
        break;
           case 'Sobremesas':
          this.cor = "#c34a84 !important";
          this.cor2 = "#6d0638 !important";
          this.icone = 'icon_categoria6.png';
        break;
         
           case 'Bolos, Doces e Pães':
          this.cor = "#c25abc !important";
          this.cor2 = "#583350 !important";
          this.icone = 'icon_categoria7.png';
        break;
         case 'Molhos e Caldos Clássicos':
          this.cor = "#e1331e !important";
          this.cor2 = "#be0200 !important";
          this.icone = 'icon_categoria8.png';
        break;
      }
    }
   
  },
  mounted(){
    this.colorir();
  },
  updated(){
    this.colorir();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.white{color:#FFF !important;}
.bt-visualizar{width:100%;border:none !important;}
.box-status{margin:0 4px;padding-top:10px;}
.txt-icones{font-size:0.9em;}

</style>
