<template>
  <div class="testar-container">
    
    <!-- -->
      <section id="secao-soma" class="container-fluid" style="background:#F9F7EA;">

        <div class="row">
            
            <div class="col-sm-12 px-lg-5">
            <br>
                      <h3 class="txt-titulo-roxo bold" >Lista de compras</h3>
                      <p class="txt-paragrafo-roxo">
Para facilitar o preparo de cada receita, é sempre importante ter tudo à mão, começando pelos ingredientes.<br>
Por isso criamos a lista de compras. Com ela você otimiza seu tempo.<br>
Basta você clicar em "ADICIONE EM LISTA DE COMPRAS”, no cabeçalho das receitas.<br>
Você pode imprimir suas listas ou visualizá-las na tela durante as compras, como for mais prático para você.
<br>
</p>
<br>

<Anuncio :id="1" padrao="/img/banner-propaganda5.png" class="d-none d-sm-block" :mobile="false" />
<Anuncio :id="1" padrao="/img/banner-propaganda5.png" class="d-sm-none" :mobile="true" />
<br>

<h3 class="txt-titulo-roxo bold" >Minha lista</h3>
<small v-if="data != ''">Criada em: {{data}}</small><hr>
<button v-if="lista.length > 0" class="btn btn-compras mx-1" @click="imprimir">IMPRIMIR LISTA</button>
<button v-if="lista.length > 0" class="btn btn-compras mx-1" @click="visualizar">VISUALIZAR PDF</button>
<button v-if="lista.length > 0" class="btn btn-compras mx-1" @click="copiar">COPIAR LISTA</button>
<button v-if="lista.length > 0" class="btn btn-compras mx-1" @click="salvar">SALVAR LISTA</button>
<br><br>

<!-- table -->
<div class="table-responsive">
<table id="tabela" class="table">
  <thead class="thead-light">
    <tr><th scope="col" colspan="6">* As quantidades são baseadas pelo rendimento cadastrado na receita.</th></tr>
     <tr>
      <th scope="col" colspan="2">Medidas Decimais</th>
      <th scope="col"></th>  
    </tr>
    <tr>
      <th scope="col">Quant</th>
      <th scope="col">UNID</th>
      <th scope="col">Ingrediente</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item,index) in lista" :key="'ingredienteLista'+index">
      <td>{{item.volume.toString().replace('.',',').replace(',0','')}}</td>
      <td>{{item.decimal}}</td>
      <td>{{item.nome}}</td>
    </tr> 
   
  </tbody>
</table>
 
</div>
<!-- -->

<h3 class="txt-titulo-roxo bold" >Receitas adicionadas</h3><hr><br>

<!-- cards-->
<div class="row">
         <CardCompras v-for="(item,index) in receitas" :key="'compra'+index" :receita="item" :showIconeDeletar="true" @deletarReceita="deletarReceita(index)" ></CardCompras>
</div>
<!-- -->
 
        
        <br><br>

        <h3 class="txt-titulo-roxo bold" >Últimas listas criadas</h3><hr>

 <div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

  <p v-if="semResultados" class="text-center bold">Sem Resultados...</p>



  <!-- table -->
<div class="table-responsive">
<table id="tabela" class="table">
  <thead class="thead-light">
   
    <tr>
      <th width="85%" scope="col">DATA E HORA</th>
      <th scope="col">VISUALIZAR OU EXCLUIR</th>
     
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item,index) in resultados" :key="'ListaCriada'+index">
      <td>{{item.LIS_DATA}}</td>
      <td>

 <span @click="editar(index,item.LIS_DATA)" title="Editar" style="cursor:pointer;margin:0 10px;"> <svg  width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
        </svg></span>

        <span @click="excluir(item.LIS_ID)" title="Excluir" style="cursor:pointer;margin:0 10px;">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
</svg>
        </span>

      </td>
      
    </tr> 
   
  </tbody>
</table>
 
</div>
<!-- -->


          </div>
                  
       </div>
                  
      </section>
<!-- -->


 <div class="modal" id="modal-compras" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>








  </div>
</template>

<style>
.txt-titulo-roxo{color:#440D45;font-weight:bold;}
.btn-compras {
    background-color: #400041;
    border: none;
    color: #fff;
    max-width:300px !important;
}
.btn-compras:hover {
    background-color: #400041;
    border: none;
    color: #fff;
}
</style>

<script>
import servidor from '@/servidor'
import CardCompras from '@/components/CardCompras'
import { jsPDF } from "jspdf"
import 'jspdf-autotable'
import Anuncio from '@/components/Anuncio'

export default {
  name: 'ListaCompras',
  metaInfo: {
    title: 'Lista de compras - Tarimba na cozinha'
  },
  components: {CardCompras,Anuncio},
  data(){
    return {
      semResultados:false,
      showLoading:false,
      texto:'',
      lista:[],
      resultados:[],
      data:''
  
    }
  },
  watch:{
    lista(){
      if(this.lista.length == 0){
        this.data = '';
      }
    }
  },
  computed:{
    getUsuario(){
      return this.$store.state.usuario.id;
    },
    receitas(){
      return this.$store.state.compras;
    }

  },
  methods:{
    editar(index,data){
      this.lista = [];
      this.data = data;
      this.$store.commit('carregarCompras',JSON.parse(this.resultados[index].LIS_DADOS));
      this.somar();
       this.$scrollTo("#secao-soma");
    },
   excluir(id){
      if(confirm('Deseja deletar esse item?')){
        fetch(servidor+'compras/deletar/'+ id)
          .then((response) => response.json())
          .then((data) => {
             console.log(data);
              this.resultados = [];
              this.getListas();
          });
      }
    },
    salvar(){
    let dados = new FormData();
    dados.append('usuario',this.getUsuario);
    dados.append('dados',JSON.stringify(this.receitas));
    this.showLoading = true;
      fetch(servidor+'compras/salvar',{method:"POST", body: dados})
          .then((response) => response.json())
          .then((data) => {
             console.log(data);
            this.showLoading = false;
            this.abrirModal('Lista de compras salva com sucesso! Agora você pode visualizar as últimas listas criadas no fim da pagina.');
            this.resultados = [];
            this.lista = [];
             this.$store.commit('carregarCompras',[]);
            this.getListas();
          });

    },
    getListas(){
    this.showLoading = true;
      fetch(servidor+'compras/getListas/'+this.getUsuario)
          .then((response) => response.json())
          .then((data) => {
            if(data == null || data.length == 0){ this.semResultados = true;}
            this.showLoading = false;
             data.forEach(element => {
                this.resultados.push(element);
            });
          });

    },
    copiar(){
      let html = '*As quantidades são baseadas pelo rendimento cadastrado na receita.\n';
      this.lista.forEach(element => {
        html += element.volume + ' - ' + element.decimal + ' : ' + element.nome + '\n';
        });

        var textArea = document.createElement("textarea");
        textArea.value = html;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        textArea.setSelectionRange(0, 99999); 

        try {
          var successful = document.execCommand('copy');
          console.log(successful);
          console.log('copiado com sucesso!');
          this.abrirModal('Lista copiada com sucesso! Agora é só colar em qualquer local desejado ex: (WhatsApp, E-mail, App de texto preferido e etc...)');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    },
    visualizar(){
   
      let doc = new jsPDF('p', 'pt');
      
      doc.autoTable({ html: '#tabela' });
      if(this.data != ''){
          doc.save('lista-de-compras: '+this.data+'.pdf');
      }else{
        doc.save('lista-de-compras.pdf');
      }
      
    },
    deletarReceita(id){
      this.$store.commit('deletarReceitaCompras',id);
      this.lista = [];
      this.somar();
      
    },
   somar(){
     this.receitas.forEach(element => {
       element.ingredientes.forEach(ingrediente => {
         if(ingrediente.decimal != "Quanto bastar"){

            let posicao = this.lista.findIndex(i => i.nome.trim() == ingrediente.nome.trim());
            let obj = {
                nome:ingrediente.nome,
                volume:parseFloat(ingrediente.volume),
                decimal:ingrediente.decimal
              }
            if(posicao == -1){
                this.lista.push(obj);
            }else{
              this.lista[posicao].volume += obj.volume;
            }
         }
   
       });
   
     });

     ///// achando os quanto bastar
     this.receitas.forEach(element => {
       element.ingredientes.forEach(ingrediente => {
         if(ingrediente.decimal == "Quanto bastar"){

            let posicao = this.lista.findIndex(i => i.nome === ingrediente.nome && i.decimal === "Quanto bastar");
            let obj = {
                nome:ingrediente.nome,
                volume:parseFloat(ingrediente.volume),
                decimal:ingrediente.decimal
              }
            if(posicao == -1){
                this.lista.push(obj);
            }
         }
   
       });
   
     });


     //////////
   },
    abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-compras').modal('show');
   },
   
    imprimir(){
       
        var style = "<style  type='text/css'>";
        style = style + "table {width: 100%;font: 20px Calibri;border:1px solid #000;}";
        style = style + "table, th {border: solid 1px #DDD; border-collapse: collapse;font: 17px Calibri;font-weight:normal;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "</style>";
        // CRIA UM OBJETO WINDOW
        var win = window.open('', '', 'height=700,width=700');
        win.document.write('<html><head>');
        win.document.write('<title>Lista de compras</title>');   // <title> CABEÇALHO DO PDF.
        win.document.write(style);                                     // INCLUI UM ESTILO NA TAB HEAD
        win.document.write('</head>');
        win.document.write('<body>');
        win.document.write('<p>*As quantidades são baseadas pelo rendimento cadastrado na receita.</p><br>');
        win.document.write('<table id="tabela" class="table">');
        win.document.write('<tr><th scope="col">Quant</th><th scope="col">UNID</th><th scope="col">Ingrediente</th></tr>');
        this.lista.forEach(element => {
          win.document.write('<tr><th scope="col">'+element.volume+'</th><th scope="col">'+element.decimal+'</th><th scope="col">'+element.nome+'</th></tr>');
        });
        win.document.write('</table>');                            // O CONTEUDO DA TABELA DENTRO DA TAG BODY
        win.document.write('</body></html>');
        win.document.close(); 	                                         // FECHA A JANELA
        win.print();               
    }
   
  
  },
  mounted(){
    this.somar();
    this.getListas();
  }
}
</script>
